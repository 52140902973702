<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M14.895 6.06769C14.895 6.18019 14.895 6.29269 14.8875 6.39769C13.74 5.97019 12.39 6.23269 11.49 7.04269C10.8825 6.49519 10.095 6.18769 9.255 6.18769C7.41 6.18769 5.91 7.69519 5.91 9.55519C5.91 11.6777 6.975 13.2302 7.995 14.2352C7.9125 14.2277 7.845 14.2127 7.785 14.1902C5.8425 13.5227 1.5 10.7627 1.5 6.06769C1.5 3.99769 3.16499 2.3252 5.21999 2.3252C6.44249 2.3252 7.52249 2.91019 8.19749 3.81769C8.87999 2.91019 9.96 2.3252 11.175 2.3252C13.23 2.3252 14.895 3.99769 14.895 6.06769Z"
      fill="#2B0C50"
    />
    <path
      d="M13.5004 7.19238C12.6979 7.19238 11.9704 7.58239 11.5204 8.18239C11.0704 7.58239 10.3504 7.19238 9.54042 7.19238C8.17542 7.19238 7.06543 8.30239 7.06543 9.68239C7.06543 10.2149 7.14793 10.7024 7.29793 11.1524C8.00293 13.3799 10.1704 14.7074 11.2429 15.0749C11.3929 15.1274 11.6404 15.1274 11.7979 15.0749C12.8704 14.7074 15.0379 13.3799 15.7429 11.1524C15.8929 10.6949 15.9754 10.2074 15.9754 9.68239C15.9754 8.30239 14.8654 7.19238 13.5004 7.19238Z"
      fill="#2B0C50"
    />
  </svg>
</template>

<script>
export default {
  name: "BreedSvg",
};
</script>

<style scoped></style>
