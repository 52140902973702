<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M21.76 15.92L15.36 4.4C14.5 2.85 13.31 2 12 2C10.69 2 9.49999 2.85 8.63999 4.4L2.23999 15.92C1.42999 17.39 1.33999 18.8 1.98999 19.91C2.63999 21.02 3.91999 21.63 5.59999 21.63H18.4C20.08 21.63 21.36 21.02 22.01 19.91C22.66 18.8 22.57 17.38 21.76 15.92Z"
      fill="#9984D4"
    />
    <path
      d="M12 14.75C11.59 14.75 11.25 14.41 11.25 14V9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V14C12.75 14.41 12.41 14.75 12 14.75Z"
      fill="#9984D4"
    />
    <path
      d="M12 17.9996C11.94 17.9996 11.87 17.9896 11.8 17.9796C11.74 17.9696 11.68 17.9496 11.62 17.9196C11.56 17.8996 11.5 17.8696 11.44 17.8296C11.39 17.7896 11.34 17.7496 11.29 17.7096C11.11 17.5196 11 17.2596 11 16.9996C11 16.7396 11.11 16.4796 11.29 16.2896C11.34 16.2496 11.39 16.2096 11.44 16.1696C11.5 16.1296 11.56 16.0996 11.62 16.0796C11.68 16.0496 11.74 16.0296 11.8 16.0196C11.93 15.9896 12.07 15.9896 12.19 16.0196C12.26 16.0296 12.32 16.0496 12.38 16.0796C12.44 16.0996 12.5 16.1296 12.56 16.1696C12.61 16.2096 12.66 16.2496 12.71 16.2896C12.89 16.4796 13 16.7396 13 16.9996C13 17.2596 12.89 17.5196 12.71 17.7096C12.66 17.7496 12.61 17.7896 12.56 17.8296C12.5 17.8696 12.44 17.8996 12.38 17.9196C12.32 17.9496 12.26 17.9696 12.19 17.9796C12.13 17.9896 12.06 17.9996 12 17.9996Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "DangerSvg",
};
</script>

<style scoped></style>
