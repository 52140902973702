import errors from "@/utils/errors";

export default {
  methods: {
    async androidNativeShare(Title, URL, Description) {
      if (typeof navigator.share === "undefined" || !navigator.share) {
        this.setError(errors.NOT_SUPPORT_NATIVE_SHARE);
      } else {
        try {
          await navigator.share({
            title: Title,
            text: Description,
            url: URL,
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
