<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M18.7 14.4602C18.35 14.8102 18.15 15.5202 18.26 16.0002L18.95 19.0102C19.24 20.2602 19.06 21.2002 18.44 21.6502C18.19 21.8302 17.89 21.9202 17.54 21.9202C17.03 21.9202 16.43 21.7302 15.77 21.3402L12.84 19.6002C12.39 19.3302 11.61 19.3302 11.16 19.6002L8.23001 21.3402C7.12001 21.9902 6.17001 22.1002 5.56001 21.6502C5.52001 21.6202 5.48001 21.5902 5.45001 21.5502L18.8 8.2002L19.97 8.4002C21.03 8.5802 21.74 9.0602 21.97 9.7602C22.19 10.4602 21.89 11.2702 21.13 12.0302L18.7 14.4602Z"
      fill="#9984D4"
    />
    <path
      opacity="0.4"
      d="M5.74001 16C5.85001 15.52 5.65001 14.81 5.30001 14.46L2.87001 12.03C2.11001 11.27 1.81001 10.46 2.03001 9.76C2.26001 9.06 2.97001 8.58 4.03001 8.4L7.15001 7.88C7.60001 7.8 8.15001 7.4 8.36001 6.99L10.08 3.54C10.58 2.55 11.26 2 12 2C12.74 2 13.42 2.55 13.92 3.54L15.64 6.99C15.77 7.25 16.04 7.5 16.33 7.67L5.07001 18.93L5.74001 16Z"
      fill="#9984D4"
    />
    <path
      d="M21.77 2.22988C21.47 1.92988 20.98 1.92988 20.68 2.22988L2.23 20.6899C1.93 20.9899 1.93 21.4799 2.23 21.7799C2.38 21.9199 2.57 21.9999 2.77 21.9999C2.97 21.9999 3.16 21.9199 3.31 21.7699L21.77 3.30988C22.08 3.00988 22.08 2.52988 21.77 2.22988Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "StarClosedSvg",
};
</script>

<style scoped></style>
