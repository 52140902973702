<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.525 6.73467L3.605 13.2822C3.5375 13.0347 3.5 12.7722 3.5 12.5022V5.49717C3.5 3.18717 5.9975 1.74717 8 2.90217L11.03 4.64967L14.0675 6.40467C14.2325 6.50217 14.39 6.60717 14.525 6.73467Z"
      fill="#2B0C50"
    />
    <path
      opacity="0.4"
      d="M14.0675 11.5946L11.03 13.3496L8.00004 15.0971C6.56754 15.9221 4.88004 15.4271 4.04004 14.2196L4.35504 14.0321L15.185 7.53711C15.935 8.88711 15.5675 10.7321 14.0675 11.5946Z"
      fill="#2B0C50"
    />
  </svg>
</template>

<script>
export default {
  name: "PlaySvg",
};
</script>

<style scoped></style>
