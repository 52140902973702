<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-show">
        <div class="popup-swipe" v-touch:swipe.bottom="close">
          <span></span>
        </div>
        <div class="popup__head">
          <div class="popup__title">
            {{ $t("show-comm-title") }}
          </div>

          <PageButton @click="close">
            <template v-slot:icon>
              <Close />
            </template>
          </PageButton>
        </div>
        <div class="popup__content">
          <Rules
            :text="$t('breeding-rules-text')"
            :title="$t('breeding-rules-title')"
          />

          <div class="page-input" :class="{ error: errorAmount }">
            <input
              type="number"
              v-model="amount"
              class="input"
              :placeholder="$t('input-amount')"
              :readonly="true"
            />

            <div class="page-input__icon">
              <CoinGoldSvg />
              SUB
            </div>
            <div class="page-input__footer">
              <div class="error-msg">
                {{ errorAmount }}
              </div>
              <div class="page-input__notice">
                Max: {{ formatNumber(max, 2) }} SUB
              </div>
            </div>
          </div>

          <MainButton
            :text="$t('show-comm-btn')"
            :disabled="!!errorAmount || !amount"
            @click="upgradeCshCommunityStatus(amount, id)"
          />

          <Keyboard @input="makeKeyboardInput" @clear="clearInput" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageButton from "../common/buttons/PageButton";
import Close from "../icons/Close";
import CoinGoldSvg from "../icons/CoinGoldSvg";
import MainButton from "../common/buttons/MainButton";
import Keyboard from "../utils/Keyboard";
import CloseModalMixin from "../../mixins/common/CloseModalMixin";
import UpgradeChsCommunityStatusMixin from "@/mixins/shoppie/UpgradeChsCommunityStatusMixin";
import Rules from "../common/Rules";
import UseKeyboardMixin from "../../mixins/keyboard/useKeyboardMixin";
import FormatNumberMixin from "../../mixins/common/FormatNumberMixin";

export default {
  name: "ShowInCommunity",
  mixins: [
    CloseModalMixin,
    UpgradeChsCommunityStatusMixin,
    UseKeyboardMixin,
    FormatNumberMixin,
  ],
  components: {
    Rules,
    Keyboard,
    MainButton,
    CoinGoldSvg,
    Close,
    PageButton,
  },
  props: {
    id: {
      required: true,
    },
  },
  data: () => ({
    amount: "",
    errorAmount: "",
  }),
};
</script>

<style scoped></style>
