import api from "@/api/api";
import successes from "@/utils/successes";
import SetProcessingMixin from "@/mixins/general/SetProcessingMixin";

export default {
  mixins: [SetProcessingMixin],
  methods: {
    async upgradeCshCommunityStatus(breedingAmount, id) {
      try {
        this.setLoading(true);
        const response = await api.upgradeCshCommunityStatus({
          id,
          breedingAmount,
        });
        if (breedingAmount) {
          this.$emit("showInCommunity", response.breedingAmount);
          this.setSuccess({ message: successes.ADD_TO_COMMUNITY });
        } else {
          this.$emit("removeFromCommunity");
          this.setSuccess({ message: successes.REMOVE_FROM_COMMUNITY });
        }
        this.setLoading(false);
        this.close();
      } catch (e) {
        console.log(e);
        this.setError({ message: e.response.data.error.message });
        this.setLoading(false);
      }
    },
  },
};
