<template>
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M14.93 4.18492H14.63L12.095 1.64992C11.8925 1.44742 11.5625 1.44742 11.3525 1.64992C11.15 1.85242 11.15 2.18242 11.3525 2.39242L13.145 4.18492H5.855L7.6475 2.39242C7.85 2.18992 7.85 1.85992 7.6475 1.64992C7.445 1.44742 7.115 1.44742 6.905 1.64992L4.3775 4.18492H4.0775C3.4025 4.18492 2 4.18492 2 6.10492C2 6.83242 2.15 7.31242 2.465 7.62742C2.645 7.81492 2.8625 7.91242 3.095 7.96492C3.3125 8.01742 3.545 8.02492 3.77 8.02492H15.23C15.4625 8.02492 15.68 8.00992 15.89 7.96492C16.52 7.81492 17 7.36492 17 6.10492C17 4.18492 15.5975 4.18492 14.93 4.18492Z"
      fill="#2B0C50"
    />
    <path
      d="M15.2372 8.02484H3.76973C3.54473 8.02484 3.31223 8.01734 3.09473 7.96484L4.03973 13.7248C4.24973 15.0148 4.81223 16.4998 7.30973 16.4998H11.5172C14.0447 16.4998 14.4947 15.2323 14.7647 13.8148L15.8972 7.96484C15.6872 8.00984 15.4622 8.02484 15.2372 8.02484ZM8.45723 12.8698C8.45723 13.1623 8.22473 13.3948 7.93223 13.3948C7.63973 13.3948 7.40723 13.1623 7.40723 12.8698V10.3948C7.40723 10.1023 7.63973 9.86984 7.93223 9.86984C8.22473 9.86984 8.45723 10.1023 8.45723 10.3948V12.8698ZM11.6672 12.8698C11.6672 13.1623 11.4347 13.3948 11.1422 13.3948C10.8497 13.3948 10.6172 13.1623 10.6172 12.8698V10.3948C10.6172 10.1023 10.8497 9.86984 11.1422 9.86984C11.4347 9.86984 11.6672 10.1023 11.6672 10.3948V12.8698Z"
      fill="#2B0C50"
    />
  </svg>
</template>

<script>
export default {
  name: "BuySvg",
};
</script>

<style scoped></style>
