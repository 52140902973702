<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-show popup-remove">
        <div class="popup-swipe" v-touch:swipe.bottom="close">
          <span></span>
        </div>
        <div class="popup__head">
          <div class="popup__title">
            {{ $t("remove-comm-title") }}
          </div>

          <PageButton @click="close">
            <template v-slot:icon>
              <Close />
            </template>
          </PageButton>
        </div>
        <div class="popup__content">
          <div class="rules-box">
            <div class="rules-box__icon">
              <DangerSvg />
            </div>

            <div class="rules-box__wrap">
              <div class="rules-box__title">
                {{ $t("remove-rules-title") }}
              </div>

              <div class="default-text">
                {{ $t("remove-rules-text") }}
              </div>
            </div>
          </div>

          <MainButton
            :text="$t('remove-comm-title')"
            @click="upgradeCshCommunityStatus(null, id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageButton from "../common/buttons/PageButton";
import Close from "../icons/Close";
import MainButton from "../common/buttons/MainButton";
import DangerSvg from "../icons/DangerSvg";
import CloseModalMixin from "../../mixins/common/CloseModalMixin";
import UpgradeChsCommunityStatusMixin from "@/mixins/shoppie/UpgradeChsCommunityStatusMixin";

export default {
  name: "RemoveFromCommunity",
  mixins: [CloseModalMixin, UpgradeChsCommunityStatusMixin],
  props: {
    id: {
      required: true,
    },
  },
  components: { DangerSvg, MainButton, Close, PageButton },
};
</script>

<style scoped></style>
