<template>
  <div class="info-page">
    <div class="info-page__char">
      <CharacterModel :image="characterImage" />
    </div>

    <div class="char-info">
      <div class="char-info__id">#{{ nftId }}</div>

      <div class="char-info__wrap">
        <div class="char-name">
          <div class="char-name__name">{{ characterName[0] }}</div>
          <div class="char-name__subname">{{ characterName[1] }}</div>
        </div>

        <div class="char-info__btns" v-if="!isMarket">
          <PageButton
            v-if="$route.name === 'Home'"
            @click="
              $router.push({
                path: to,
              })
            "
          >
            <template v-slot:icon>
              <EyeSvg />
            </template>
          </PageButton>

          <PageButton
            v-if="isCommunityFeed && !isInCommunity && !isOnMarket"
            @click="isShowModalVisible = true"
          >
            <template v-slot:icon>
              <StarSvg />
            </template>
          </PageButton>

          <PageButton
            v-if="isCommunityFeed && isInCommunity"
            @click="isRemoveModalVisible = true"
          >
            <template v-slot:icon>
              <StarClosedSvg />
            </template>
          </PageButton>

          <PageButton @click="makeShare">
            <template v-slot:icon>
              <UploadSvg />
            </template>
          </PageButton>
        </div>
      </div>

      <KindBadge />
    </div>

    <div class="owner">
      <div class="owner__img">
        <img src="@/assets/img/user.png" alt="" />
      </div>

      <div class="owner__wrap">
        <div class="owner__title">
          {{ $t("char-owner-title") }}
        </div>

        <div class="owner__name">{{ creatorName }}</div>
      </div>
    </div>

    <div class="info-page__btns" v-if="!isMarket">
      <button
        class="game-btn"
        v-if="!isInGame && !isOnMarket"
        @click="goToGame"
      >
        <play-svg /> {{ $t("btn-act") }}
      </button>

      <button
        class="game-btn"
        @click="goToBreeding"
        v-if="!isInGame && !isOnMarket"
      >
        <breed-svg /> {{ $t("btn-breed") }}
      </button>

      <button class="game-btn" v-if="isOnMarket && !isCshOwner && !isInGame">
        <buy-svg /> {{ $t("btn-buy") }}
      </button>
      <button
        class="game-btn"
        v-if="!isOnMarket && isCshOwner && !isInGame"
        @click="goToSell"
      >
        <buy-svg /> {{ $t("btn-sell") }}
      </button>
      <button
        class="game-btn"
        v-if="isOnMarket && isCshOwner && !isInGame"
        @click="goToSell"
      >
        <buy-svg /> {{ $t("remove-from-sale-sell") }}
      </button>
    </div>
    <transition name="fade">
      <ShowInCommunity
        :id="characterId"
        v-if="isShowModalVisible"
        @close="isShowModalVisible = false"
        @showInCommunity="showInCommunity"
      />
    </transition>
    <transition name="fade">
      <RemoveFromCommunity
        :id="characterId"
        v-if="isRemoveModalVisible"
        @close="isRemoveModalVisible = false"
        @removeFromCommunity="removeFromCommunity"
      />
    </transition>
  </div>
</template>

<script>
import PageButton from "../common/buttons/PageButton";
import CharacterModel from "../character/СharacterModel";
import EyeSvg from "../icons/EyeSvg";
import UploadSvg from "../icons/UploadSvg";
import KindBadge from "../common/badges/KindBadge";
import ShareMixin from "@/mixins/share/ShareMixin";
import StarSvg from "../icons/StarSvg";
import ShowInCommunity from "../modals/ShowInCommunity";
import StarClosedSvg from "../icons/StarClosedSvg";
import RemoveFromCommunity from "../modals/RemoveFromCommunity";
import PlaySvg from "../icons/PlaySvg";
import BreedSvg from "../icons/BreedSvg";
import BuySvg from "../icons/BuySvg";

export default {
  name: "CryptoShoppieInfo",
  mixins: [ShareMixin],
  props: {
    to: {
      type: String,
      required: false,
    },
    creatorName: {
      type: String,
      required: true,
    },
    characterImage: {
      type: String,
      required: true,
    },
    nftId: {
      type: String,
      required: true,
    },
    characterName: {
      type: Array,
      required: true,
    },
    characterId: {
      type: String,
      required: true,
    },
    isCommunityFeed: {
      default: false,
      required: false,
    },
    isInCommunity: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isMarket: {
      default: false,
    },
    isOnMarket: {
      default: false,
    },
    isInGame: {
      default: false,
    },
    isCshOwner: {
      default: false,
    },
    cshId: {
      required: false,
      default: () => "",
    },
  },
  data: () => ({
    isShowModalVisible: false,
    isRemoveModalVisible: false,
  }),
  methods: {
    goToSell() {
      this.$router.push(`${this.$route.fullPath}?sell=true`);
    },
    goToBreeding() {
      this.$emit("goToBreeding");
    },
    goToGame() {
      this.$emit("goToGame");
    },
    showInCommunity(val) {
      this.$emit("showInCommunity", val);
    },
    removeFromCommunity() {
      this.$emit("removeFromCommunity");
    },
    makeShare() {
      const lang = localStorage.getItem("lang") || "en";
      this.androidNativeShare(
        this.characterName,
        `${window.location.origin}/${lang}/cryptoshoppie/${this.characterId}`,
        "Description"
      );
    },
  },
  components: {
    BuySvg,
    BreedSvg,
    PlaySvg,
    RemoveFromCommunity,
    StarClosedSvg,
    ShowInCommunity,
    StarSvg,
    KindBadge,
    UploadSvg,
    EyeSvg,
    CharacterModel,
    PageButton,
  },
};
</script>
